@import url(bootstrap-icons/font/bootstrap-icons.css);

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #F3E6C8;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.milost-login-logo {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


.contact-managment-area,
.send-sms-area,
.contact-search-area,
.smsgroups-managment-area,
.sheets-manual-import-area,
.new-lunch-area,
.actual-lunch-area,
.history-lunch-area,
.lunch-order-area,
.red-bordered-area {
  border-radius: 16px;
  border: 3px solid #FF3227 !important;
  min-height: 50px;
}

.send-sms-area,
.contact-search-area,
.contact-managment-area,
.sheets-manual-import-area,
.new-lunch-area,
.actual-lunch-area,
.history-lunch-area,
.red-bordered-area {
  margin-bottom: 10px;
}

.send-sms-textarea,
.lunch-entry-textarea {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

div.smsgroups-managment-area,
div.contact-managment-area,
div.contact-search-area,
div.send-sms-area {
  padding-left: 1%;
}

div.contact-managment-area,
div.send-sms-area {
  padding-right: 1%;
}

.btn.btn-custom {
  padding: 0.2rem 0.2rem;
}

.smsgroup-data {
  margin-right: 10px;
}

.sentsms-info {
  margin-top: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
  padding: 6px 9px;
}

.language-switcher {
  width: 35px;
  height: 25px;
  margin-top: 9px;
}

.lunch-print-checkbox {
  width: 20px;
  height: 20px;
  margin-left: 18%;
}

.text-20 {
  font-size: 20px;
}

.l-margin-10 {
  margin-left: 10%;
}

.l-margin-5 {
  margin-left: 5%;
}

.l-margin-11 {
  margin-left: 11%;
}

.captcha {
  text-align: -webkit-center;
}


.text-centered {
  text-align: center;
}

.lunch-picture {
  background-image: url("../public/pictures/lunch.svg");
  background-repeat: no-repeat;
  background-size: 27vh;
  background-position-x: 85%;
}

.sk-vlajka {
  background-image: url("../public/flags/SK_vlajka.png");
}

.en-vlajka {
  background-image: url("../public/flags/EN_vlajka.png");
}

.float-left {
  float: left;
}

.menuIcon {
  font-size: 35px;
  color: black;
}

.pointer-cursor {
  cursor: pointer;
}

.language-flag {
  width: 35px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}